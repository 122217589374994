<template>
        <section class="gv-billboard-wrapper gv-post-feature">
        <article class="gv-billboard">
            <!-- <div class="gv-billboard-poster">
                <img src="https://2pyzcd2sdlnr1r3xjh15mrbn-wpengine.netdna-ssl.com/wp-content/uploads/IMG_62696679D1F5-1.jpeg"
                    class="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt=""
                    srcset="https://2pyzcd2sdlnr1r3xjh15mrbn-wpengine.netdna-ssl.com/wp-content/uploads/IMG_62696679D1F5-1.jpeg 1400w, https://2pyzcd2sdlnr1r3xjh15mrbn-wpengine.netdna-ssl.com/wp-content/uploads/IMG_62696679D1F5-1-250x200.jpeg 250w, https://2pyzcd2sdlnr1r3xjh15mrbn-wpengine.netdna-ssl.com/wp-content/uploads/IMG_62696679D1F5-1-700x560.jpeg 700w, https://2pyzcd2sdlnr1r3xjh15mrbn-wpengine.netdna-ssl.com/wp-content/uploads/IMG_62696679D1F5-1-768x614.jpeg 768w, https://2pyzcd2sdlnr1r3xjh15mrbn-wpengine.netdna-ssl.com/wp-content/uploads/IMG_62696679D1F5-1-120x96.jpeg 120w"
                    sizes="(max-width: 1400px) 100vw, 1400px" /> 
            </div> -->
            <div class="gv-billboard-poster"> 
                <!-- gv-billboard-postwrap -->
                <div class="gv-post-container container">
                    <div class="row clearfix gv-post-sharemeta">
                        <div class="col-sm-6 col-md-7 gv-post-tags">
                            <a href="#"
                                class="link-blue">#ApnaPointShubham</a>
                        </div>
                      
                    </div>
                    <div class="row clearfix">
                        <div class="col-sm-6">
                            <h1 class="gv-ctrl-title gv-liner-simple gv-post-title">
                                    The 18 to 28 Debate: Is College Worth It? 
                            </h1>
                        </div>
                        <div class="col-sm-6 text-left">
                            <h5 class="gv-h-slab gv-post-date">
                                2 months ago <span class="middot">&middot;</span>
                                7 min read </h5>
                            <p>

                                The world is changing, and it’s important to acknowledge that certain long held
                                beliefs should be re-examined. So many of us are stuck doing things in an outdated
                                way and it’s only leading us to unhappiness. With that in mind, let’s rethink the
                                paths people can take from ages 18 to 28. I don’t have all the answers, so after
                                reading this piece, leave a comment so I can get your perspective on the 18 to 28
                                debate.
                            </p>
                            <!-- <div class="text-right">
                                <a href="https://www.garyvaynerchuk.com/the-18-to-28-debate-is-college-worth-it/"
                                    class="gv-post-permalink link-blue">Read More &gt;&gt;</a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </section>
</template>

<script>
export default {
  name: 'HomeBanner'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

