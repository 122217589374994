<template>
  <div class="gv-body-wrapper">
   <Header />
     <div class="btn_wrapper"> 
         <h3 class="success-message">You are successfully logged in</h3>
         <div class="home-btn" @click="$router.push('/')"> Home </div>
     </div>
    <Footer/>
  </div>
</template>
<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import Footer from '../components/Footer'
import Header from '../components/Header'
import HomeSideBanner from '../components/HomeSideBanner'
import ArticleDetail from '../components/ArticleDetail'
import axios from 'axios'
import BaseUrl from '../assets/constant'
import { SessionHelper } from "../utils/session";


export default {
  components:{
    Header,
    Footer,
    ArticleDetail,
    HomeSideBanner
  },
  data() {
    return {
      artileData: [],
    };
  },
  methods:{ 
  },
   async mounted() {
      console.log(this.$route.params.id)
      try { 
          const {data} = await this.$store.dispatch({type: 'createUser'})
          console.log('data data data data',data)
           if(data.success){
              console.log('data',data)
              this.$store.commit('LOGINUSERDATA', data.list)
              SessionHelper.setCookie('UserDetail', data.list)
            }    
      } catch (error) {
            console.log(error)
      } 
  },
};
</script>
