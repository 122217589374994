<template>
  <article
    id="post-10874"
    class="gv-post post-10874 post type-post status-publish format-standard has-post-thumbnail hentry category-askgaryvee category-life-lessons"
  >
  <div v-for="(item, index) in list" :key="index">
  
    <div class="gv-post-img">
      <a href="#">
        <img
          :src="`${urlBase}/${item.articleImage}`"
          class="attachment-large size-large wp-post-image"
          alt=""
          sizes="(max-width: 700px) 100vw, 700px"
        />
      </a>
    </div>

    <div class="row clearfix gv-post-sharemeta">
      <div class="col-sm-5 col-md-6 gv-post-tags">
        <a href="#" class="link-blue">#ApnaPointShubham</a>
      </div>
      
      <div class="col-xs-5 col-sm-6 col-md-6 pull-right text-right">
        <div class="gv-post-sharing hidden-xs">
          <div class="row">
            <div class="col-xs-4">
              <a
                href="#"
                data-permalink="#"
                data-platform="facebook"
                class="gv-ctrl-share gv-btn gv-btn-block gv-btn-short gv-btn-s-fb"
                title="Share on Facebook"
                ><i class="fa fa-facebook"></i
              ></a>
            </div>
            <div class="col-xs-4">
              <a
                href="#"
                data-permalink="#"
                data-platform="twitter"
                class="gv-ctrl-share gv-btn gv-btn-block gv-btn-short gv-btn-s-tw"
                title="Tweet on Twitter"
                ><i class="fa fa-twitter"></i
              ></a>
            </div>
            <div class="col-xs-4">
              <a
                href="#"
                data-permalink="#"
                data-platform="linkedin"
                class="gv-ctrl-share gv-btn gv-btn-block gv-btn-short gv-btn-s-in"
                title="Share on LinkedIn"
                ><i class="fa fa-linkedin"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row clearfix">
      <div class="col-sm-6"  >
        <!-- {{list[0].description}} -->
        <h1 class="gv-ctrl-title gv-post-title gv-liner-simple">
          <a href="#">{{item.heading}}</a>
        </h1>
      </div>
      <div class="col-sm-6 text-left">
        <h5 class="gv-h-slab gv-post-date">
          <!-- months ago ----min read -->
          {{new Date(item.createdAt).toLocaleDateString()}}  <span class="middot">-</span>{{new Date(item.createdAt).toLocaleTimeString()}} 
        </h5>
        <div v-html="item.description">
        </div>
        <p>
          <span href="#" class="gv-post-permalink link-blue" @click="$router.push(`/article/${item._id}`)">Read More &gt;&gt;</span>
        </p>
      </div>
    </div>

    </div>


  </article>
</template>

<script>

export default {
  name: "ArticleHome",
  props: ['list', 'urlBase'],
  methods:{
  
  },
  mounted(){
    console.log('=this.list==', this.list)
    console.log('==BaseUrl',this.urlBase)
    
  }
};
</script>
