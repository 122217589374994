<template>
    <div>
     <div id="gv-main-footer" class="gv-widget-wrapper gv-widget-fulllength gv-footer">
            <div class="container">
                <div class="form-group row">
                    <div class="col-md-6" @click="$router.push('/')">
                        <div class="gv-mark-type" v-if="!user">
                            Shubham Jain
                        </div>

                         <div class="gv-mark-type" v-if="user">
                            {{user.name}}
                        </div>
                    </div>
                    <div class="col-sm-10 col-md-9 gv-nav-footer">
                        <ul id="menu-footer-menu" class="menu">
                            <li id="menu-item-2095"
                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2095"><a
                                    href="/">Home</a></li>
                            <li id="menu-item-81"
                                class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-5 current_page_item menu-item-81">
                                <a href="#" aria-current="page">Blog</a></li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-3">
                        <h6 class="gv-h-slab gv-h-slab-normal gv-h-grey">Keep in Touch</h6>
                        <div id=gv_widgets_socialprops-4 class="gv-widget gv-widget-socialprops clearfix text-left">
                            <div class="sidebar-c-7">
                                <SocialIcon wrapClass="wrap-social-item" linkWrapClass="hover-social" iconClass="fa fa-instagram"/>

                                <SocialIcon wrapClass="wrap-social-item" linkWrapClass="hover-social sp-youtube" iconClass="fa fa-youtube"/>

                                <SocialIcon wrapClass="wrap-social-item" linkWrapClass="hover-social sp-facebook" iconClass="fa fa-facebook"/>

                                <SocialIcon wrapClass="wrap-social-item" linkWrapClass="hover-social sp-twitter" iconClass="fa fa-twitter"/>

                                <SocialIcon wrapClass="wrap-social-item" linkWrapClass="hover-social sp-linkedin" iconClass="fa fa-linkedin"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-8 col-md-push-1">
                        <div class="gv-widget-mailinglist horizontal">
                            <div class="gv-form">
                                <div class="gv-widget-alert"><span class="message">Lorem Ipsum Dolor Test</span><a
                                        href="#alert:dismiss" class="gv-widget-ctrl close">&times;</a></div>
                                <form name="gv-mc-horizontal">
                                    <div class="row">
                                        <h5 class="gv-h-slab gv-h-slab-normal text-center hidden-md">
                                            Get all my best stuff in your inbox. </h5>
                                        <div
                                            
                                            style="width: 100%;display: flex;align-items: center;justify-content: center;">
                                            <a  :href="`${url}/auth/google`" target="_blank"
                                                :class="user ? 'gv-btn gv-btn-red disabled' : 'gv-btn gv-btn-red'">Subscribe</a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-8 col-md-6 gv-footer-fineprint">
                        <p>©Shubham Jain , 2021<br /></p>
                    </div>
                </div>
            </div>
        </div>

        </div>
</template>

<script>
import SocialIcon from '../CommonComponent/SocialIcon'
import { SessionHelper } from "../utils/session";
import constant from '../utils/constant'

export default {
  name: 'Footer',
  components:{
      SocialIcon
  },
  data(){
      return{
          user: '',
          url: ''
      }
  },
  watch: {
    '$store.state.userDetails': function(newVal, oldVal){
        console.log('=newVal=header=', newVal)
        this.user = newVal
    }
  },
  mounted(){
    this.url = constant.BaseUrl
    this.user = SessionHelper.getCookie('UserDetail') != '' ? SessionHelper.getCookie('UserDetail') : ''
    this.$store.commit('LOGINUSERDATA', this.user)
    console.log('==header=', this.user)
  }   
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

