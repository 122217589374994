// import fetch from 'cross-fetch'
import { checkStatus, parseJSON } from "./responseHandler";
import axios from "axios";
import { SessionHelper } from "./session";
import constant from './constant'
// Make an api call 
export default async ( url, method = "post", body) => {
  console.log('=body=', body)
  let allHeaders= Object.assign({},SessionHelper.getHeader());
  
  return axios(`${constant.BaseUrl}${url}`, {
    method,
    data: body,
    headers:allHeaders
  })
    .then(checkStatus)
    .catch(error => {
      throw error;
    });
};
