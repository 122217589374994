<template>
    <div class="gv-body-wrapper">
        <Header @handleSelectCategory="handleArticlelist"/>
        <HomeBanner/>
        <div class="gv-content-wrapper no-shadow">
            <div class="gv-page-wrapper container">
                <div class="row">
                    <div id="blogroll" class="col-md-8">
                     <ArticleHome :list="list" :urlBase="urlBase"/>    
                    <!-- paginatio components -->
                    </div> 
                    <!-- #blogroll -->
                    <HomeSideBanner/>
                </div><!-- .row -->
            </div>
            <!--! page-wrapper -->
        </div>
        <!-- content-wrapper -->
        <HomeShareBanner />
        <Footer />
     <div @click="handleAddtoHome" class="addtohome">Add to home Screen </div>
    </div>
</template>
<script>
// @ is an alias to /src
// import ''
import Footer from '../components/Footer'
import Header from '../components/Header'
import HomeBanner from '../components/HomeBanner'
import HomeShareBanner from '../components/HomeShareBanner'
import HomeSideBanner from '../components/HomeSideBanner'
import ArticleHome from '../components/ArticleHome'
// import axios from 'axios'
import BaseUrl from '../assets/constant' 


export default {
  name: 'Home',
  data(){
    return{
      deferredPrompt: null,
      list: [],
      urlBase: BaseUrl
    }
  },
  watch:{
      list: function(newVal, oldVal){

      }
  },
  methods:{
     handleArticlelist(value){
        try { 
          this.$store.dispatch({type: 'getArticle', payload: value}).then(data=>{
            console.log('data data data data',data)
            if(data.data.success){
                this.list = data.data.list
            }    
          })
        } catch (error) {
          console.log(error)
        } 
    },
    handleAddtoHome(){
        this.deferredPrompt.prompt()
        this.deferredPrompt.userChoice
          .then(choiceResult => {
            if(choiceResult.outcome === 'accepted') {
              console.log('user accepted A2HS prompt')
            } else {
              console.log('user dismissed A2HS prompt')
            }
          this.deferredPrompt = null
      })
    }
  },
  components: {
    Footer,
    Header,
    HomeShareBanner,
    HomeBanner,
    HomeSideBanner,
    ArticleHome
  },
   async mounted(){
     await this.handleArticlelist('')

     window.addEventListener('beforeinstallprompt',  (e) => {
                // Prevent Chrome 67 and earlier from automatically showing the prompt
                e.preventDefault()
                this.deferredPrompt = e
                // console.log('==-=-event=-',e)
                // e.prompt()
                // Stash the event so it can be triggered later.
                // window.Cstmbeforeinstallprompt = e;
     });
  }
}
</script>
