<template>
   <div id="gv-main-nav" class="gv-nav-wrapper">
            <!-- <div id="hello-bar" class="gv-hello-bar-wrapper" style="display:flex;">
                <nav class="gv-hello-bar">
                    <ul id="menu-hello-bar" class="menu">
                        <li id="menu-item-8224"
                            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-8224"><a
                                href="#">🎧 Subscribe to my podcast 🎧</a></li>
                    </ul>
                </nav>
            </div> -->

            <div class="gv-nav-inner">
                <div class="gv-cta-community" v-if="!user" @click="$router.push('/')">
                        <img src="https://s3.amazonaws.com/gv2016wp/wp-content/uploads/20191212171239/text-me.png">
                </div>
                <div class="gv-cta-community" v-if="user" @click="$router.push('/')">
                        <img :src="user.profile_img">
                </div>
                <div class="row">
                    <!-- <div class="gv-nav-mobile">
                        <button class="gv-nav-toggle">
                            Menu
                        </button>
                    </div> -->
                    <div class="gv-mark-type" v-if="user" @click="$router.push('/')">
                            {{user.name}}
                    </div>
                    <div class="gv-mark-type" v-if="!user" @click="$router.push('/')">
                            Shubham Jain
                    </div>

                    <div class="gv-nav-desktop">
                        <nav class="gv-nav">
                            <ul id="menu-main-nav" class="menu">
                                <li @click="handleSelectCategory('')" id="menu-item-55"
                                    class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-5 current_page_item menu-item-55">
                                    <a :class="selected === '' ? 'active' : ''">Blog</a></li>
                                <li @click="handleSelectCategory('tech')" id="menu-item-55"
                                    class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-5 current_page_item menu-item-55">
                                     <a :class="selected === 'tech' ? 'active' : ''">Technology</a></li>

                                 <li @click="handleSelectCategory('news')" id="menu-item-55"
                                    class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-5 current_page_item menu-item-55">
                                    <a :class="selected === 'news' ? 'active' : ''">News</a></li>

                                 <li @click="handleSelectCategory('food')" id="menu-item-55"
                                    class="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-5 current_page_item menu-item-55">
                                     <a :class="selected === 'food' ? 'active' : ''">Food</a></li>
                                    
                                <!-- <li id="menu-item-7930"
                                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-7930"><a
                                        href="#">Contact</a></li>
                                <li id="menu-item-8182"
                                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-8182"><a
                                        href="#">My Story</a></li> -->
                            </ul>
                        </nav>
                    </div>

                    <button data-href="#gv-search|modal-open" class="gv-search-toggle gv-modal-ctrl">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
            </div>
        </div>
</template>

<script>
import { SessionHelper } from "../utils/session";

export default {
  name: 'Header',
  data(){
      return{
          user: '',
          selected: ''
      }
  },
  watch: {
    '$store.state.userDetails': function(newVal, oldVal){
        console.log('=newVal=header=', newVal)
        this.user = newVal
    }
  },
  methods:{
    handleSelectCategory(value){
        this.selected = value
        this.$emit('handleSelectCategory', value)
    }
  },
  mounted(){
    this.user = SessionHelper.getCookie('UserDetail') != '' ? SessionHelper.getCookie('UserDetail') : ''
    this.$store.commit('LOGINUSERDATA', this.user)
    console.log('==header=', this.user)
  }          
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

