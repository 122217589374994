import Vue from "vue";
import Vuex from "vuex";
import ApiCaller from "../utils/apicaller";
import types from "./types";
import { SessionHelper } from "../utils/session";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userDetails: null,
    specificUserDetail: null
  }, 
  mutations: {
    [types.LOGINUSERDATA]: (state, payload) => {
      state.userDetails = payload;
    },

    [types.SPECIFICUSERDETAIL]: (state, payload) => {
      state.specificUserDetail = payload;
    },

  }, 
  actions: { 
    getArticle({ commit }, { type, payload }) {
      return ApiCaller(`/article?value=${payload}`, "get");
    },
    getArticleDetail({ commit }, { type, payload }) {
      console.log('==payload=', payload)
      return ApiCaller(`/article/${payload}`, "get");
    },
    createUser({ commit }, { type, payload }) {
      return ApiCaller("/createuser", "get");
    },
    createArticle({ commit }, { type, payload }) {
      return ApiCaller("/article/new", "post", payload);
    },
   
  }
});
 