<template>
   <div id="gv-mobile-share" class="gv-overlay gv-overlay-black">
            <div class="social-container">
                <a href="#" data-permalink="#" data-platform="facebook"
                    class="gv-ctrl-share gv-btn gv-btn-s-gb gv-round sp-circle sp-facebook" title="Share on Facebook">
                    <i class="fa fa-facebook"></i>
                </a>
                <a href="#" data-permalink="#" data-platform="twitter"
                    class="gv-ctrl-share gv-btn gv-btn-s-gb gv-round sp-circle sp-twitter" title="Share on Twitter">
                    <i class="fa fa-twitter"></i>
                </a>
                <a href="#" data-permalink="#" data-platform="linkedin"
                    class="gv-ctrl-share gv-btn gv-btn-s-gb gv-round sp-circle sp-linkedin" title="Share on LinkedIn">
                    <i class="fa fa-linkedin"></i>
                </a>
                <a href="#" data-permalink="#" data-platform="pinterest"
                    class="gv-ctrl-share gv-btn gv-btn-s-gb gv-round sp-circle sp-pinterest" title="Share on Pinterest">
                    <i class="fa fa-pinterest"></i>
                </a>
                <a href="#" data-permalink="#" data-platform="googleplus"
                    class="gv-ctrl-share gv-btn gv-btn-s-gb gv-round sp-circle sp-google" title="Share on Google+">
                    <i class="fa fa-google-plus"></i>
                </a>
                <a href="#" data-permalink="#" data-platform="reddit"
                    class="gv-ctrl-share gv-btn gv-btn-s-gb gv-round sp-circle sp-reddit" title="Share on Reddit">
                    <i class="fa fa-reddit"></i>
                </a>
                <a href="#" data-permalink="#" data-platform="stumble"
                    class="gv-ctrl-share gv-btn gv-btn-s-gb gv-round sp-circle sp-stumble" title="Share on StumbleUpon">
                    <i class="fa fa-stumbleupon"></i>
                </a>
                <a href="#" data-permalink="#" data-platform="email"
                    class="gv-ctrl-share gv-btn gv-btn-s-gb gv-round sp-circle sp-email" title="Email Us">
                    <i class="fa fa-envelope"></i>
                </a>
            </div>
            <a href="#gv-mobile-share|modal-close" class="gv-modal-ctrl gv-close gv-close-gray">×</a>
        </div>
</template>

<script>
export default {
  name: 'HomeShareBanner'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

