<template>
   <div class="form-wrapper">
     <h1>Article form</h1>
     <div class="input-wrap">
        <label>Title</label>
        <input v-model="form.title" type="text" placeholder="title">
     </div>
      <div class="input-wrap">
        <label>Description</label>
        <vue-editor v-model="form.description"></vue-editor>
     </div>
      <!-- <div class="input-wrap">
        <label>Category</label>
        <input v-model="form.category" type="text" placeholder="Category">
     </div> -->
      <div class="custom-select-main">
         <label>Category</label>

        <b-form-select
                v-model="form.category"
                :options="category"
                class="md-12 custom-select"
                value-field="id"
                text-field="name"
             ></b-form-select>
        </div>
      <div class="input-wrap">
        <label>Heading</label>
        <input v-model="form.heading" type="text" placeholder="Heading">
     </div>
      <div class="input-wrap">
        <label>Author</label>
        <input v-model="form.Author" type="text" placeholder="Author">
     </div>
     <div class="input-wrap">
        <label>File</label>
        <input type="file" @change="onFileChange" placeholder="File">
     </div>

     <div class="btn-submit" @click="handleSubmit"> Submit </div>
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from 'axios'
import constant from '../utils/constant'

import BaseUrl from '../assets/constant'

export default {
  name: 'ArticleForm',
  components: {
      VueEditor
  },
  data(){
      return{
         url: BaseUrl,
         category: [
            {name: 'All', id: ''},
            {name: 'Technology', id: 'tech'},
            {name: 'News', id: 'news'},
            {name: 'Food', id: 'food'}
         ],
         form: {
            title: "Hello world",
            description:"hello world description india",
            category:"all",
            heading:"Real Talk",
            Author:"60538d469d4ae7000439cc7e",
            articleImage: null,
         }
      }
  },
  methods:{
      onFileChange(e){
          console.log("jk;l",e.target.files[0])
          this.form.articleImage = e.target.files[0] 
      },
      
     getFormData(obj) {
        var formData = new FormData();
        for (var key in obj) {
            formData.append(key, obj[key]);
        }
        return formData;
      } ,
      async handleSubmit(){
         var formData = this.getFormData(this.form)

         try {
            const {data} = await axios.post(`${constant.BaseUrl}/article/new`, formData)
            console.log('response',data)
         } catch (error) {
            console.log(error)
         }


      //   axios.post(`${BaseUrl}/article/new`, formData).then(response => {
      //       console.log('response',response)
      //   })
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

