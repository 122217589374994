<template>
     <section class="gv-billboard-wrapper gv-page-feature" v-if="articleImage">
        <div class="gv-billboard">
          <div class="gv-billboard-poster">
            <img
              :src="`https://my-first-blog-shubh.herokuapp.com/${articleImage}`"
              class="attachment-post-thumbnail size-post-thumbnail wp-post-image"
              alt=""
              sizes="(max-width: 1024px) 100vw, 1024px"
            />
          </div>
        </div>
      </section> 
</template>

<script>
export default {
  name: 'ArticleDetail',
  props: ['articleImage']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

