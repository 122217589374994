<template>
  <div class="col-md-4 gv-global-sidebar">
    <div class="gv-widget clearfix">
      <div class="gv-widget-mailinglist sidebar">
        <h2 class="h1 text-right">
          <p>
            Subscribe <span class="nobreak">for access</span>
            <span class="nobreak">to exclusive</span> content.
          </p>
        </h2>
        <div class="gv-form">
            <div class="clear text-right">
              <a
                href="#"
                target="_blank"
                class="gv-btn gv-btn-red"
                >Subscribe</a
              >
            </div>
        </div>
      </div>
    </div>
    <div
      id="gv_widgets_socialprops-2"
      class="gv-widget gv-widget-socialprops clearfix text-center"
    >
      <div class="sidebar-c-5">
        <span class="wrap-social-item">
          <a
            class="sp-circle sp-facebook"
            href="#"
            alt=""
            target="_blank"
          >
            <i class="fa fa-facebook"></i>
          </a>
        </span>
        <span class="wrap-social-item">
          <a
            class="sp-circle sp-youtube"
            href="#"
            alt=""
            target="_blank"
          >
            <i class="fa fa-youtube"></i>
          </a>
        </span>
        <span class="wrap-social-item">
          <a
            class="sp-circle sp-instagram"
            href="#"
            alt=""
            target="_blank"
          >
            <i class="fa fa-instagram"></i>
          </a>
        </span>
        <span class="wrap-social-item">
          <a
            class="sp-circle sp-twitter"
            href="#"
            alt=""
            target="_blank"
          >
            <i class="fa fa-twitter"></i>
          </a>
        </span>
        <span class="wrap-social-item">
          <a
            class="sp-circle sp-snapchat"
            href="#"
            alt=""
            target="_blank"
          >
            <i class="fa fa-snapchat"></i>
          </a>
        </span>
      </div>
    </div>
    <!-- <div class="gv-widget gv-widget-articleunit">
      <div class="gv-articleunit">
        <h4 class="gv-h-welter50 text-right">Must Read</h4>
        <div class="gv-articleunit-content">
          <div class="gv-articleunit-thumb">
            <a
              href="https://www.garyvaynerchuk.com/the-definition-of-a-post-creative-strategist/"
            >
              <img
                src="https://s3.amazonaws.com/gv2016wp/wp-content/uploads/20200918143622/IMG_2F18783D00EC-1-400x234.jpeg"
                class="attachment-ad-unit-size size-ad-unit-size wp-post-image"
                alt=""
                srcset="
                  https://s3.amazonaws.com/gv2016wp/wp-content/uploads/20200918143622/IMG_2F18783D00EC-1-400x234.jpeg 400w,
                  https://s3.amazonaws.com/gv2016wp/wp-content/uploads/20200918143622/IMG_2F18783D00EC-1-300x175.jpeg 300w
                "
                sizes="(max-width: 400px) 100vw, 400px"
              />
            </a>
          </div>
          <h3 class="h2 gv-liner-simple">
            <a
              href="https://www.garyvaynerchuk.com/the-definition-of-a-post-creative-strategist/"
            >
              The Definition Of A Post Creative Strategist
            </a>
          </h3>
        </div>
        <div class="gv-articleunit-footer">
          <span class="gv-articuleunit-date"> Sep 18, 2020 </span>
          <a
            href="https://www.garyvaynerchuk.com/the-definition-of-a-post-creative-strategist/"
          >
            Read Now >>
          </a>
        </div>
      </div>
    </div> -->
    <!-- <div class="gv-widget gv-widget-articleunit">
      <div class="gv-articleunit">
        <h4 class="gv-h-welter50 text-right">Must Read</h4>
        <div class="gv-articleunit-content">
          <div class="gv-articleunit-thumb">
            <a
              href="https://www.garyvaynerchuk.com/what-you-need-to-know-about-linkedin-stories/"
            >
              <img
                src="https://2pyzcd2sdlnr1r3xjh15mrbn-wpengine.netdna-ssl.com/wp-content/uploads/IMG_A766477D230C-1-400x234.jpeg"
                class="attachment-ad-unit-size size-ad-unit-size wp-post-image"
                alt=""
                srcset="
                  https://2pyzcd2sdlnr1r3xjh15mrbn-wpengine.netdna-ssl.com/wp-content/uploads/IMG_A766477D230C-1-400x234.jpeg 400w,
                  https://2pyzcd2sdlnr1r3xjh15mrbn-wpengine.netdna-ssl.com/wp-content/uploads/IMG_A766477D230C-1-300x175.jpeg 300w
                "
                sizes="(max-width: 400px) 100vw, 400px"
              />
            </a>
          </div>
          <h3 class="h2 gv-liner-simple">
            <a
              href="https://www.garyvaynerchuk.com/what-you-need-to-know-about-linkedin-stories/"
            >
              What you need to know about LinkedIn Stories
            </a>
          </h3>
        </div>
        <div class="gv-articleunit-footer">
          <span class="gv-articuleunit-date"> Oct 06, 2020 </span>
          <a
            href="https://www.garyvaynerchuk.com/what-you-need-to-know-about-linkedin-stories/"
          >
            Read Now >>
          </a>
        </div>
      </div>
    </div> -->
    <div class="clearfix"></div>
    <!-- <div class="gv-widget gv-widget-events theme-black">
      <h3 class="h1">Upcoming Events</h3>
      <div class="event-listing">
        <div class="clearfix event event-2-col">
          <a
            href="https://www.abcreativex.com/"
            class="event-linker"
            target="_blank"
          >
            <div class="widget-event-date text-center">
              <div class="h3 gv-h-welter50">Jan</div>
              <div class="h2 gv-h-welter50">28</div>
            </div>
            <div class="widget-event-title">
              <h3 class="event-title">Anheuser-Busch Creative X</h3>
              <h4 class="event-location">Virtual</h4>
            </div>
            <div class="widget-event-info">
              <a
                href="https://www.abcreativex.com/"
                target="_blank"
                class="gv-btn gv-btn-blue gv-btn-block"
                >Info</a
              >
            </div>
          </a>
        </div>

        <div class="clearfix event event-2-col">
          <a
            href="https://events.getjobber.com/events/details/jobber-jobber-professional-development-day-presents-jobber-professional-development-day/#/"
            class="event-linker"
            target="_blank"
          >
            <div class="widget-event-date text-center">
              <div class="h3 gv-h-welter50">Mar</div>
              <div class="h2 gv-h-welter50">24</div>
            </div>
            <div class="widget-event-title">
              <h3 class="event-title">Jobber Professional Development Day</h3>
              <h4 class="event-location">Virtual</h4>
            </div>
            <div class="widget-event-info">
              <a
                href="https://events.getjobber.com/events/details/jobber-jobber-professional-development-day-presents-jobber-professional-development-day/#/"
                target="_blank"
                class="gv-btn gv-btn-blue gv-btn-block"
                >Info</a
              >
            </div>
          </a>
        </div>

        <div class="clearfix event event-2-col">
          <a
            href="https://nobull2020.com/"
            class="event-linker"
            target="_blank"
          >
            <div class="widget-event-date text-center">
              <div class="h3 gv-h-welter50">Apr</div>
              <div class="h2 gv-h-welter50">08</div>
            </div>
            <div class="widget-event-title">
              <h3 class="event-title">The No Bull Business Conference</h3>
              <h4 class="event-location">Syracuse, NY</h4>
            </div>
            <div class="widget-event-info">
              <a
                href="https://nobull2020.com/"
                target="_blank"
                class="gv-btn gv-btn-blue gv-btn-block"
                >Info</a
              >
            </div>
          </a>
        </div>

        <div class="clearfix event event-2-col">
          <a
            href="https://grit.trixstar.com/"
            class="event-linker"
            target="_blank"
          >
            <div class="widget-event-date text-center">
              <div class="h3 gv-h-welter50">Apr</div>
              <div class="h2 gv-h-welter50">29</div>
            </div>
            <div class="widget-event-title">
              <h3 class="event-title">
                GRIT Series Presents An Evening with Gary Vaynerchuk
              </h3>
              <h4 class="event-location">Edmonton, AB, Canada</h4>
            </div>
            <div class="widget-event-info">
              <a
                href="https://grit.trixstar.com/"
                target="_blank"
                class="gv-btn gv-btn-blue gv-btn-block"
                >Info</a
              >
            </div>
          </a>
        </div>
      </div>
      <div class="event-widget-footer text-center">
        <a
          href="#"
          id="cal-reply-subscribe"
          class="gv-btn gv-btn-blue gv-btn-block"
          target="_blank"
          title="Subscribe on CalReply"
        >
          <i class="fa fa-calendar-check-o hidden-md"></i>&nbsp;&nbsp;Get
          Notifications
        </a>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "HomeSideBanner",
};
</script>

