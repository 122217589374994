import VueCookie from "vue-cookies";
// session kyes

export const CONTENT_TYPE = "Content-Type";

// creating session class
export class SessionHelper {
  // save general data in cookie
  static setCookie(_key, _value) {
    VueCookie.set(_key, _value);
  }

  // get general data in cookie
  static getCookie(_key) {
    return VueCookie.get(_key) || "";
  }

  // delete cookie
  static clearCookie(_key) {
    SessionHelper.setCookie(_key, "");
  }
  
  // fetching header
  static getHeader(contentType = "application/json") {
    return {
      [CONTENT_TYPE]: contentType,
      'token': SessionHelper.getCookie('token')
    };
  }
}
// [X_AUTH_ID]: SessionHelper.getCookie(X_AUTH_ID),
// [JWT_TOKEN]: SessionHelper.getCookie(JWT_TOKEN),
// [Authorization]: `Bearer ${SessionHelper.getCookie(JWT_TOKEN)}`,
// [LANG]: SessionHelper.getCookie(LANG) || "en"
