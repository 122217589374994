<template>
   <span :class="wrapClass">
      <a
        :class="linkWrapClass"
        href="#"
        alt=""
        target="_blank"
        >
        <i :class="iconClass"></i>
        </a>
    </span>
</template>

<script>
export default {
  name: 'SocialIcon',
  props: ['wrapClass','linkWrapClass', 'iconClass']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

